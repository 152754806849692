import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {PersistenceService} from "./persistence.service";
import {AuthService} from "./auth.service";
import {Platform} from "@ionic/angular";


@Injectable()
export class HttpInterceptorProvider implements HttpInterceptor {

    constructor(
        public persistanceService: PersistenceService,
        public authService: AuthService,
        private platform: Platform,
    ) {

    }

    // Intercepts all HTTP requests!
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this.persistanceService.getToken();
        const authType = this.authService.getType();
        const loginRole = this.authService.getLoginRole();

        // Start with the original request
        let clonedReq = request;

        // Add token if it exists
        if (token) {
            clonedReq = this.addToken(clonedReq, token);
        }

        // Add auth type if it exists
        if (authType) {
            clonedReq = this.addAuthType(clonedReq, authType);
        }

        // Add login role if it exists
        if (loginRole) {
            clonedReq = this.addLoginRole(clonedReq, loginRole);
        }

        clonedReq = this.addDeviceInfo(clonedReq);

        return next.handle(clonedReq).pipe();

    }

    // Adds the token to your headers if it exists
    private addToken(request: HttpRequest<any>, token: any) {
        if (token) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                setHeaders: {
                    Accept: `application/json`,
                    // 'Content-Type': `application/json`,
                    Authorization: `Bearer ${token}`
                }
            });
            return clone;
        }

        return request;
    }

    // Adds the authType
    private addAuthType(request: HttpRequest<any>, type: any) {
        if (type) {
            return request.clone({
                setHeaders: {
                    'Auth-Type': `${type}`
                }
            });
        }
        return request;
    }

// Adds the login role if existed
    private addLoginRole(request: HttpRequest<any>, role: any) {
        if (role) {
            return request.clone({
                setHeaders: {
                    'Login-Role': `${role}`
                }
            });
        }
        return request;
    }

    private addDeviceInfo(request: HttpRequest<any>) {
        let isAppPlatform = this.platform.is('hybrid') ? 'app' : 'web';
        let os = this. getOSVersion();
        let device =  this.platform.is('mobile') ? 'mobile' : this.platform.is('tablet') ? 'tablet' : 'desktop'
        let screenDimensions = this.platform.width() + 'x' + this.platform.height()

        return request.clone({
            setHeaders: {
                'App-Platform': `${isAppPlatform}`,
                'App-OS': `${os}`,
                'App-Device': `${device}`,
                'App-Screen': `${screenDimensions}`,
            }
        });
    }

    getOSVersion() {
        let userAgent = navigator.userAgent;
        let os = 'unknown'

        if (userAgent.indexOf("Win") !== -1) {
            os = "windows";
        } else if (userAgent.indexOf("Mac") !== -1) {
            os = "macOS";
        } else if (/Linux/.test(userAgent) && !/Android/.test(userAgent)) {
            os = "linux";
        } else if (userAgent.indexOf("X11") !== -1 && userAgent.indexOf("Linux") === -1) {
            os = "unix";
        } else if (/Android/.test(userAgent)) {
            os = "android";
        } else if (/iPhone|iPad|iPod/.test(userAgent)) {
            os = "ios";
        }

        return os;
    }

}
