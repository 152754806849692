import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {AuthApiResponse} from "../../types/apiResponse.interface";
import {API_URL} from "../../../providers/config";

const apiUrl = API_URL;

@Injectable({
    providedIn: 'root'
})
export class ApiService {


    constructor(
        private http: HttpClient,
    ) {
    }


    public sendApiRequest(method: string, postData: any, typeMethod: string = 'get', isFormData: boolean = false) {
        // let headers = new HttpHeaders()
        //   // .set('Content-Type', 'application/json')
        //   .set('Accept', 'application/json');

        let headers = new HttpHeaders();
        let body;

        if (postData instanceof FormData) {
            headers = headers.set('Accept', 'application/json');
            body = postData;
        } else {
            headers = headers
                .set('Content-Type', 'application/json')
                .set('Accept', 'application/json');
            body = JSON.stringify(postData);
        }

        let options = {
            headers: headers
        };

        let result;
        switch (typeMethod) {
            case 'get':
                result = this.http.get(apiUrl + method, options);
                break;
            case 'post':
                result = this.http.post(apiUrl + method, body, options);
                break;
            case 'put':
                result = this.http.put(apiUrl + method, body, options);
                break;
            case 'delete':
                result = this.http.delete(apiUrl + method, options);
                break;
            default:
                result = this.http.post(apiUrl + method, body, options);
        }
        return result;
    }

}
